import { appWithTranslation } from "next-i18next";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/global.scss";

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);

  return getLayout(<Component {...pageProps} />);
}

export default appWithTranslation(MyApp);
